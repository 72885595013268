import React from "react"
import LayoutFull from "../components/layout-full"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <LayoutFull>
    <h2 className="mt-12 text-center text-4xl leading-9 font-extrabold text-gray-900">
      This is awkward...
    </h2>
    <p className="mt-2 text-center text-base leading-5 text-gray-600">
      We can't find the page that you are looking for.
    </p>
    <p className="mt-2 text-center text-base leading-5 text-gray-600">
      It looks like you've followed a broken link or entered a URL that doesn't
      exist on this site.
    </p>
    <p className="mt-8 text-center text-base leading-5 text-gray-600">
      <Link to={"/"} className="text-primary-600 hover:text-primary-700">
        &larr; Head back to the homepage
      </Link>
    </p>
  </LayoutFull>
)

export default NotFoundPage
